$colorBlack:                          #000000;
$colorBlackTranslucent:               rgba(44, 47, 56, 0.7);
$colorOrange:                         #f29b00;
$colorRed:                            #d20515;
$tableBackgroundColor:                #321f00;
$colorGray:                           #808191;
$colorWhite60:                        rgba(255, 255, 255, 0.4);
$colorYellow:                         #ffff00;

// Black translucent
$colorThemeBlackTranslucentBlack:     #2c2f38;
$colorThemeBlackTranslucentBlackDark: #000000;
$colorThemeBlackTranslucentWhite:     #ffffff;

// White
$colorThemeWhiteBlack:                #000000;
$colorThemeWhiteGrayLight2:           #dedede;
$colorThemeWhiteWhite:                #ffffff;

///
/// old
///
///
///
$colorBlack16:                        rgba(0, 0, 0, 0.16);
$colorGrayDark:                       #1f2128;
$colorGrayDark2:                      #343434;
$colorGrayLight:                      #d4d4d4;
$colorGrayLight2:                     #dedede;
$colorGreen:                          #ebfaf3;
$colorWhite:                          #ffffff;

:export
{
    colorBlack:      $colorBlack;
    colorBlack16:    $colorBlack16;
    colorGrayDark:   $colorGrayDark;
    colorGrayDark2:  $colorGrayDark2;
    colorGray:       $colorGray;
    colorGrayLight:  $colorGrayLight;
    colorGrayLight2: $colorGrayLight2;
    colorGreen:      $colorGreen;
    colorRed:        $colorRed;
    colorWhite:      $colorWhite;
}