@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{
    text-transform: uppercase;
    font-size:      12px;
    bottom:         -3px;
    position:       relative;
    letter-spacing: 1.8px;

    .dayWrapper
    {
        margin-right: 26px;
    }

    strong
    {
        font-size:      20px;
        font-weight:    500;
        text-transform: none;
        margin-right:   5px;

        letter-spacing: 1px;
    }
}