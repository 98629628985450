@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

$bodySidePadding: 20px;

.imageWrapper
{
    width:            162px;
    height:           162px;
    border-radius:    81px;
    box-shadow:       0 3px 6px 0 $colorBlack16;
    background-color: $colorWhite;
    position:         absolute;
    top:              -20px;
    left:             -60px;
    justify-content:  center;
    align-items:      center;
    display:          flex;

    img
    {
        max-width:  70%;
        max-height: 70%;
    }
}

.wrapper
{
    width:            611px;
    height:           115px;
    border-radius:    7px;
    box-shadow:       0 3px 6px 0 $colorBlack16;
    border:           solid 1px $colorGrayLight2;
    background-color: $colorWhite;
    position:         absolute;
    top:              -3px;
    left:             50%;
    z-index:          $zIndexScoreOverlay;
    margin:           0 0 0 -305px;

    dl
    {
        margin:     0;
        padding:    0;
        display:    flex;
        flex-wrap:  wrap;
        box-sizing: border-box;

        dt,
        dd
        {
            $height:       58px;

            box-sizing:    border-box;
            display:       block;
            color:         $colorBlack;
            font-size:     20px;
            font-weight:   bold;
            height:        $height;
            line-height:   $height;
            border-bottom: 1px solid $colorGrayLight2;

            &:last-child
            {
                border-bottom: 0;
            }
        }

        dt
        {
            padding-left: 120px;
            text-align:   left;
            width:        85%;
            display:      flex;
        }

        dd
        {
            padding-right: 20px;
            text-align:    right;
            width:         15%;
        }
    }
}