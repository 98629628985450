@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{

}

.container
{
    height:        60px;
    background:    $colorOrange;
    border-radius: 0 0 5px 5px;
    box-shadow:    0 3px 6px 0 $colorBlack16;
    display:       flex;
}

.left
{
    padding:     0 0 0 20px;
    flex:        1;
    align-items: center;
    text-align:  left;
    line-height: 15px;
    color:       $colorBlack;
    font-size:   16px;
    font-weight: 500;
    display:     none;

    @media (min-width: $screen-md)
    {
        display: flex;
    }
}

.center
{
    flex: 1;
}

.headerBadge
{
    background-image:    url('../../../assets/images/header-badge-black.svg');
    background-size:     contain;
    background-position: center;
    width:               363px;
    padding:             5px 0 18px 0;
    box-sizing:          border-box;
    height:              73px;
    text-align:          center;
    font-size:           24px;
    font-weight:         normal;
    color:               $colorWhite;
    margin:              0 auto;
    display:             flex;
    align-items:         center;
    justify-content:     center;
    filter:              drop-shadow(1px 6px 14px rgba(0, 0, 0, 0.2));

    svg
    {
        height: 82%;
    }
}

.right
{
    flex:            1;
    align-items:     center;
    text-align:      right;
    line-height:     15px;
    padding:         0 20px 0 0;
    color:           $colorBlack;
    opacity:         0;
    transition:      opacity 0.25s linear;
    font-size:       10px;
    justify-content: flex-end;
    display:         none;

    @media (min-width: $screen-md)
    {
        display: flex;
    }

    &:hover
    {
        opacity: 1;
    }
}