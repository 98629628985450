@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';

$bodySidePadding: 29px;

*
{
    margin:  0;
    padding: 0;
}

body,
html
{
    height: 100%;
}

:global #root
{
    min-height: 100%;
}

body
{
    padding:                 0;
    margin:                  0;
    font-family:             $fontInter;
    min-width:               320px;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat:       no-repeat;
    background-position:     left 50px;
    background-size:         100%;
    background-color:        $tableBackgroundColor;

    @media (min-width: $screen-md)
    {
        padding:             0 $bodySidePadding 0 $bodySidePadding;
        background-image:    url('./assets/images/vertragswechsel/background-desktop.jpg');
        background-repeat:   no-repeat;
        background-position: center center;
        background-size:     cover;
        overflow:            hidden;
    }
}

.mobileHeaderWrapper
{
    img
    {
        width: 100%;

        @media (min-width: $screen-md)
        {
            display: none;
        }
    }
}

.appContent
{
    padding:    0;
    margin:     0;
    min-height: 100%;

    @media (min-width: $screen-md)
    {
        padding: 0 0 $bodySidePadding 0;
    }
}

$heightRow1-sm:   300px;
$heightRow2-sm:   300px;
$heightRow1-md:   40vh;
$heightRow2-md:   28vh;
$heightRow1:      60vh;
$heightRow2:      28vh;
$gapSize:         41px;
$widthColumn1-sm: 100%;
$widthColumn2-sm: 100%;
$widthColumn1-md: 40%;
$widthColumn2-md: 100% - $widthColumn1-md;
$widthColumn1:    28%;
$widthColumn2:    100% - $widthColumn1;

.homeContent
{
    display:   flex;
    flex-wrap: wrap;
    padding:   0;

    @media (min-width: $screen-md)
    {
        padding: 0 20px;
    }
}

.homeQualifyingTop,
.homeQualifyingBottom
{
    $width:     818px;

    box-sizing: border-box;
    padding:    0;
    background: $tableBackgroundColor;
    width:      100%;
    height:     80vh;
    margin:     0 0 0 0;

    @media (min-width: $screen-md)
    {
        width:    $width;
        height:   70vh;
        position: absolute;
        top:      15vh;
        left:     50%;
        margin:   0 0 0 $width/-2;
    }
}